@import url('https://fonts.googleapis.com/css?family=Gochi+Hand|Montserrat:400,700');

$primary: #e40d7d;
/* MAIN COLOR */
$secondary: #007999;
/* SECONDARY COLOR */
$blk: #333;
/* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa;
$border-radius: 0px;
/* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;

html,
body {
    height: 100%;
}

body {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    font-family: 'Montserrat',
    sans-serif;
    font-size: 16px;

}
h1,h2,h3 {
    font-family: 'Gochi Hand',
    cursive;

}

.flash {
    display: none;
}

q,
blockquote {
    quotes: "“""”";
}

.navbarFixed {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;
}

/* Change autocomplete styles in WebKit */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: 1px solid #0065dd;
    -webkit-text-fill-color: #0065dd;
    -webkit-box-shadow: 0 0 0px 1000px #000 inset;
    transition: background-color 5000s ease-in-out 0s;
}

nav.navbar {
    z-index: 1000;
    border-bottom: 4px solid $blk;
    border-radius: $border-radius;

    .navbar-nav {
        >li>a {
            font-size: 1em;
            text-align: center;
            background: $primary;
            border-radius: 10px;
            margin-left: 5px;
            margin-top: 25px;
            display: flex;
            align-items: center;
            color: $wht;

            @media (max-width: 767px) {
                margin-top: 0;
                padding: 6px 0px;
                display: inline-block;
                background: none;
                color: $primary;
                font-size: 1em;
            }

            &:focus,
            &:active {
                background: $primary;
                color: $wht;
                outline: 0;
            }

            &:hover {
                background: $primary;
                color: $blk;

                @media (max-width: 1024px) {
                    background: transparent;
                    color: $blk;
                    outline: 0;
                }
            }
        }
    }
}

.navbar-right {
    margin-top: 0px;
}

.navbar-toggle {
    margin: 20px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
}


/** LOGIN FORM **/

@mixin btn {
    border: none;
    border-radius: $border-radius;
    background: $primary;
    color: $wht;
    padding: 0.5em 2em;
    font-size: 1.25em;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    display: inline-block;
    margin: 2em 0em;
    transition: 0.7s ease all;

    &:hover {
        background: lighten($primary, 10%);
        color: lighten($wht, 10%);
        text-decoration: none;

        @media (max-width: 1024px) {
            background: $secondary;
            color: $wht;
            box-shadow: 0px 0px 5px $blk;
        }
    }
}

.btn-default {
    @include btn;
}

.modal-dialog {
    max-width: 300px;
    text-align: center;
    margin: 6em auto;

    .close {
        display: none;
    }

    .modal-content {
        color: $primary;

        h2 {
            text-align: center;
        }
    }

    input {
        border: 1px solid #777;
        text-align: center;
    }

    input#username,
    input#password {
        @media (max-width: 1024px) {
            font-size: 16px;
        }
    }

    button {
        background: transparent;
        color: $wht;
        display: block;
        border: none;
        padding: 1em 2em;
        margin: 0 auto;
        font-size: 18px;

        &:hover {
            background: transparent;
            color: #fff;
            box-shadow: none;
            text-shadow: 0px 0px 3px $blk;
            border: none;
        }
    }

    input[type="submit"] {
        @include btn;
        display: block;
        width: 75%;
        font-size: 18px;
        margin: 0 auto;
    }
}

.modal-header,
.modal-footer {
    background: $primary;
    color: $wht;
}

.modal-open {
    overflow: auto;
    padding-right: 0px !important;
}

input#username {
    margin-bottom: 20px;
}


/** END LOGIN FORM **/

footer {
    padding: 50px 0px 20px;
    background: $primary;
    color: $footerLinks;
    font-size: 15px;

    a {
        color: $footerLinks;
        white-space: nowrap;
        font-size: 15px;

        @media (max-width: 990px) {
            font-size: 13px;
        }

        &:hover {
            color: lighten($footerLinks, 10%);
            outline: 0 !important;
            text-decoration: none;
        }

        &:focus {
            color: $wht;
            outline: 0 !important;
            text-decoration: none;
        }
    }

    p {
        font-size: 15px;

        @media (max-width: 990px) {
            font-size: 13px;
        }
    }
}

input#username,
input#password {
    width: 100%;

    @media (max-width: 1024px) {
        font-size: 16px;
    }
}

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}

a,
a:hover,
a:focus,
a:active {
    outline: 0 !important;
}

.logo {
    max-height: 100px;
    padding: 0.5em;
}

@media(max-width: 767px) {
    .logo {
        max-height: 70px;
    }

    .navbar-header a {
        float: left;
    }
}

@media (max-width: 600px) {
    .logo {
        max-width: 225px;
    }

    .navbar-toggle {
        margin-top: 5px;
    }
}

.top-pad {
    padding: 3em;
    background: white;

    @media (max-width: 990px) {
        padding: 1em;
    }
}

.flex {
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 1024px) {
        display: block;
        /* may need to be inline-block */
    }
}

.terms,
.privacy {
    p {
        font-size: 17px;
    }

    & ol {
        li {
            font-size: 17px;
        }

        & ol li {
            font-size: 15px;
        }
    }
}

.header {
    background: linear-gradient(to bottom, rgba($primary, .5), rgba($blk, .7)),
                url('../img/img1.jpg');
    background-size: cover;
    padding: 350px 0;
    @media(max-width:991px){
        padding: 250px 0;
    }
    @media(max-width:767px){
        padding: 150px 0;
    }
    @media(max-width:500px){
        padding: 100px 0;
    }
    @media(max-width:450px){
       background-position: 70% 0;
    }
    h1 {
        color: $wht;
        font-size: 6em;
        text-align: center;
        @media(max-width:1024px){
            font-size: 4em;
        }
        @media(max-width:767px){
            font-size: 3em;
        }
        @media(max-width:500px){
            font-size: 2em;
        }
    }
    
}

.headline {
    font-family: 'Yesteryear', cursive;
    font-size: 120px;
    color: $wht;
    text-align: center;
}

.title2 {
    font-family: 'Yesteryear', cursive;
    font-size: 60px;
    margin-bottom: -10px;
    color: $wht;
    text-align: center;
}

.title3 {
    font-family: 'Yesteryear', cursive;
    font-size: 60px;
    margin-bottom: -10px;
    color: $wht;
    text-align: center;

    @media (max-width: 990px) {
        display: none;
    }
}

.tagline {
    font-family: 'Economica', sans-serif;
    font-size: 30px;
    text-align: center;
    color: $wht;
}

.container-fluid {
    padding-left: 0;
    padding-right: 0;
}

.title {
    font-family: 'Economica', sans-serif;
    font-size: 32px;
    text-align: center;
    font-weight: 600;
}

.text {
    font-family: 'Economica', sans-serif;
    font-size: 18px;
    text-align: center;
}

.center {
    display: block;
    margin: 0 auto;
}

.press {
    margin-top: 20px;
    margin-bottom: 20px;
}

.color {
    background: darken($primary, 10%);
    border-top: 5px solid $wht;
}

.outro {
    background: linear-gradient(to bottom, rgba($primary,.7), rgba($blk, .5)),
        url('../img/img2.jpg');
    background-size: cover;
    background-position: right 0;
    padding: 350px 0;

    @media(max-width: 991px) {
        padding: 250px 0;
    }

    @media(max-width:767px) {
        padding: 150px 0;
    }

    @media(max-width:500px) {
        padding: 100px 0;
    }
    @media(max-width:350px) {
        background-position: 70% 0;
    }

    h1 {
        color: $wht;
        font-size: 6em;
        text-align: center;

        @media(max-width: 1024px) {
            font-size: 4em;
        }

        @media(max-width:767px) {
            font-size: 3em;
        }

        @media(max-width:500px) {
            font-size: 2em;
        }
    }

}

